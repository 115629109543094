<template>
  <div :class="classes" :data-setup-step-progress="id">
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  computed: {
    classes() {
      return [
        'setupProgressionBar-option',
        { 'setupProgressionBar-done': this.id === this.currentStep },
        { 'setupProgressionBar-selected': this.id === this.currentStep }
      ];
    }
  }
};
</script>
