import * as Vue from "vue"
import SetupProgressBar from "./components/accounts_page/SetupProgressBar.vue";
import SetupForm from "./components/accounts_page/SetupForm.vue.erb";

const element = document.querySelector("#app")
if (element !== null) {
  const app = Vue.createApp({});
  app.component('setup-progress-bar', SetupProgressBar);
  app.component('setup-form', SetupForm);
  app.mount("#app");
}
