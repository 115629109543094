<template>
  <div class="setupProgressionBar-container">
    <SetupOption
      v-for="step in steps"
      :key="step.id"
      :id="step.id"
      :label="step.label"
      :currentStep="currentStep"
    ></SetupOption>
  </div>
</template>

<script>
import SetupOption from './SetupOption.vue';

export default {
  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      steps: [
        { id: 1, label: 'Business Details' },
        { id: 2, label: 'Account Information' },
        { id: 3, label: 'Build Website' }
      ],
    };
  },
  components: {
    SetupOption
  },
};
</script>
