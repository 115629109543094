<template>
  <div class="form-step" data-setup-step="4">
    <h3>Account Information</h3>
    <p>Create your admin account to manage your services, availability, organise appointments and much more.</p>
    <hr>

    <FormErrors :errors="errors" />

    <div class="form-group">
      <label for="email" class="form-label">Email</label>
      <input type="email" id="email" v-model="email" class="form-control"/>
    </div>
    <div class="form-group">
      <label for="password" class="form-label">Password</label>
      <input type="password" id="password" v-model="password" class="form-control"/>
    </div>
    <div class="form-group">
      <label for="password_confirmation" class="form-label">Password Confirmation</label>
      <input type="password" id="password_confirmation" v-model="password_confirmation" class="form-control"/>
    </div>

    <div class="d-flex justify-content-between">
      <button @click="goBack" type="button" class="btn btn-success">Back</button>
      <button @click="submit" type="button" :class="buttonClasses">Next</button>
    </div>
  </div>
</template>

<script>
import FormErrors from './FormErrors.vue';

export default {
  components: {
    FormErrors
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    updateData: {
      type: Function,
      required: true
    },
    goNext: {
      type: Function,
      required: true
    },
    goBack: {
      type: Function,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      errors: [],
      validForm: false
    };
  },
  computed: {
    buttonClasses() {
      return {
        'btn-secondary': !this.validForm,
        'btn-success': this.validForm,
        'btn': true
      };
    }
  },
  watch: {
    email(newVal) {
      this.checkFormInputs();
      this.updateData('email', newVal);
    },
    password(newVal) {
      this.checkFormInputs();
      this.updateData('password', newVal);
    },
    password_confirmation(newVal) {
      this.checkFormInputs();
      this.updateData('password_confirmation', newVal);
    },
  },
  methods: {
    checkFormInputs() {
      if (this.email && this.password && this.password_confirmation) {
        this.validForm = true;
      }
    },
    validateForm() {
      this.errors = [];
      if (!this.email) {
        this.errors.push('Email required.');
      }
      if (this.email && !/\S+@\S+\.\S+/.test(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.password) {
        this.errors.push('Password required.');
      } else {
        // Strong password: At least 8 characters, includes uppercase, lowercase, number, and special character
        const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!strongPasswordPattern.test(this.password)) {
          this.errors.push('Password must be strong: At least 8 characters, includes uppercase, lowercase, number, and special character.');
        }
      }
      if (!this.password_confirmation) {
        this.errors.push('Password confirmation required.');
      }
      if (this.password !== this.password_confirmation) {
        this.errors.push('Passwords do not match.');
      }

      if (this.errors.length > 0) {
        this.validForm = false;
        return false
      } else {
        this.validForm = true;
        return true
      }
    },
    submit() {
      if (this.validateForm()) {
        this.handleSubmit();
        this.goNext();
      }
    }
  }
};
</script>

