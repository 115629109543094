<template>
  <div class="form-step" data-setup-step="5">
    <ul class="building-steps" v-if="buildWebsite">
      <li><img :src="buildingAccountSrc" class="loader-icon"/><b>Building Account</b></li>
      <li><img :src="configuredSubdomainSrc" class="loader-icon"/><b>Configuring Sub-Domain</b></li>
      <li><img :src="configuredServerSrc" class="loader-icon"/><b>Creating Web Server</b></li>
      <li><img :src="registeredSSLSrc" class="loader-icon"/><b>Registering your SSL Certificate</b></li>
      <li><img :src="configuredSSLSrc" class="loader-icon"/><b>Configuring your SSL Certificate</b></li>
    </ul>

    <p v-if="buildWebsite && !websiteBuilt && (!buildError && websiteBuilt)" class="text-danger">Your website is currently being setup, please wait a few moments. If you don't have time to wait, an email will be sent over to you when this is finished.</p>
    <p v-if="websiteBuilt">Your website has now been setup, click on the link below to view your website. An email has also been sent to you so you don't lose your details.</p>
    <p v-if="buildError">Ops! Something has gone wrong when building your site. We've tracked the details and we'll email you when this has been fixed. Really sorry!</p>
  </div>
</template>

<script>
export default {
  props: {
    buildWebsite: {
      type: Boolean,
      required: true
    },
    websiteBuilt: {
      type: Boolean,
      required: true
    },
    buildingAccountSrc: {
      type: String,
      required: true
    },
    configuredSubdomainSrc: {
      type: String,
      required: true
    },
    configuredServerSrc: {
      type: String,
      required: true
    },
    registeredSSLSrc: {
      type: String,
      required: true
    },
    configuredSSLSrc: {
      type: String,
      required: true
    },
    buildError: {
      type: Boolean,
      required: true
    }
  },
};
</script>
