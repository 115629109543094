<template>
  <button @click="action" class="btn btn-primary" type='button'>
    {{ label }}
  </button>
</template>

<script>
export default {
  props: ['label', 'action'],
};
</script>
